
import { Vue, Options } from 'vue-class-component'
import CreateAccountModal from '../components/CreateAccountModal.vue'
import axios from '@/plugins/axios'
import { ElMessageBox } from 'element-plus'

import { UserType } from "@/constants/UserType"

interface Result1 {
  total: number
  data: []
}
interface Item {
  _id: string
}
interface Result {
  message: string
  data: []
  status: boolean
}
@Options({
  components: {
    CreateAccountModal
  }
})
export default class AccountPage extends Vue {
  title = 'Accounts'
  listAccount = []
  currentPage = 1
  totalRows = 0
  perPage = 20
  activeName = ''
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  rules = {
    nameAccount: [{ required: true, message: 'Please input Activity name' }]
  }
  dialogAddAccount = false
  dialogUpdateAccount = false
  dialogUpdateUser = false

  isEmailChanged = false
  isStatusChanged = false
  isPasswordChanged = false
  isAccountChanged = false
  isNoteChanged = false
  isAccountOwneChanged = false
  platformPartnerId = ''
  userType = -1
  UserType = UserType

  // update account
  id = ''
  email = ''
  status = ''
  password = ''
  confirmPassword = ''
  account = ''
  nameAccount = ''
  note = ''
  newBraintreeId = ''
  skip_2fa = false
  fisrtLogin = false
  isAccountOwner = false
  isFreeAccount = false
  isShowSearch = false
  itemData: any = {}
  userData: any = null
  condition: any = {}
  multipleSelection = []
  listAccountEmail: any = []
  listAllAccount: any = []
  listPlatformPartner: any = []

  async created() {
    //  await this.getList(1);
    await this.getListPlatformParner()
    await this.getAll()
    await this.getAllAccount(1)
    await this.getAll()
  }

  async loadAccountInfo(item: any) {
    this.newBraintreeId = ''
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result1 = {
      total: 0,
      data: []
    }
    this.account = item._id

    res = await axios.get(`admin/user?account=${item._id}`, {
      headers: this.headers,
      params: {
        page: 1,
        limit: this.perPage
      }
    })
    if (res) {
      this.userData = res.data
      this.userData.forEach((user: any) => {
        if (item.owner == user._id) {
          user.isAccountOwner = true
          this.newBraintreeId = user.braintree_id
        }
        if (item.banned.length > 0) {
          if (item.banned.includes(user._id)) {
            user.banned = true
          }
        }
      })
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async getList(page = 1) {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: Result1 = {
      total: 0,
      data: []
    }
    res = await axios.get(`admin/user?email=${this.condition.email}`, {
      headers: this.headers,
      params: {
        page: page,
        limit: this.perPage
      }
    })
    if (res) {
      this.listAccount = res.data
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  handleCurrentChange(index = 1) {
    this.currentPage = index
    this.activeName = ''
    this.getAllAccount(index)
  }
  handleSizeChange(val: number) {
    this.currentPage = 1
    this.perPage = val
    this.activeName = ''
    this.getAllAccount(this.currentPage)
  }

  handleSelectionChange(val: any) {
    this.multipleSelection = val
  }
  toggleSelection() {
    const refFrorm = this?.$refs.multipleTable as HTMLFormElement
    refFrorm.clearSelection()
  }
  async getAll() {
    let res = await axios.get('admin/account/get-all', {
      headers: this.headers
    })
    if (res) {
      this.listAccountEmail = res.data
    }
  }

  async getAllAccount(page: any) {
    await this.$store.dispatch('setLoading', true, { root: true })
    this.activeName = ''

    let res: Result1 = await axios.get(`admin/account`, {
      headers: this.headers,
      params: {
        page: page,
        limit: this.perPage,
        name: this.condition.name
      }
    })
    if (res) {
      this.totalRows = res.total
      this.listAllAccount = res.data
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  async getListPlatformParner () {
    let res = await axios.get('admin/platform/platform-partners', {
      headers: this.headers
    })
    if (res) {
      this.listPlatformPartner = res.data
    }
  }

  async handleDelete() {
    if (this.multipleSelection.length) {
      ElMessageBox.confirm(
        'Do you want to delete seleted account?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(async () => {
        var params
        var ids = []
        let item: Item
        for (item of this.multipleSelection) {
          ids.push(item._id)
          params = { idList: ids }
        }
        let res: Result
        res = await axios.delete('user/delete', {
          params: params,
          headers: this.headers
        })
        if (res) {
          this.$message.success(res.message)
          this.getList(this.currentPage)
        }
      })
    } else {
      this.$message.warning('There are not account selected !!!')
    }
  }
  handleAddModal() {
    this.dialogAddAccount = true
  }
  async handleAddAfter() {
    this.dialogAddAccount = false
    await this.getList(this.currentPage)
    await this.getAllAccount(this.currentPage)
  }

  handleUpdateModalAccount(row: any) {
    this.account = row._id
    this.nameAccount = row.name
    this.note = row.note
    this.isFreeAccount = row.isFreeAccount || false
    this.dialogUpdateAccount = true
  }

  async handleBanUser(row: any) {
    ElMessageBox.confirm('Do you want to ban this user ?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(async () => {
      let res: Result
      await this.$store.dispatch('setLoading', true, { root: true })
      res = await axios.post(
        'admin/account/ban-account',
        { accountId: this.account, userId: row._id },
        {
          headers: this.headers
        }
      )
      if (res.status) {
        this.$message.success(res.message)
        await this.getAllAccount(this.currentPage)
        await this.$store.dispatch('setLoading', false, { root: true })
      } else {
        this.$message.warning(res.message)
        await this.getAllAccount(this.currentPage)
        await this.$store.dispatch('setLoading', false, { root: true })
      }
    })
  }

  async handleRemoveBanUser(row: any) {
    ElMessageBox.confirm('Do you want to unlock this user ?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(async () => {
      let res: Result
      await this.$store.dispatch('setLoading', true, { root: true })
      res = await axios.post(
        'admin/account/remove-ban-account',
        { accountId: this.account, userId: row._id },
        {
          headers: this.headers
        }
      )
      if (res.status) {
        this.$message.success(res.message)
        await this.getAllAccount(this.currentPage)
        await this.$store.dispatch('setLoading', false, { root: true })
      } else {
        this.$message.warning(res.message)
        await this.getAllAccount(this.currentPage)
        await this.$store.dispatch('setLoading', false, { root: true })
      }
    })
  }

  handleUpdateModalUser(row: any) {
    // this.account = row.account[0]?._id;
    // this.nameAccount = row.account[0]?.name;
    this.status = row.status
    this.email = row.email
    this.id = row._id
    this.skip_2fa = row.skip_2fa
    this.fisrtLogin = row.fisrtLogin || false
    this.isAccountOwner = row.isAccountOwner || false
    this.platformPartnerId = row.platformPartner
    this.userType = row.type
    // this.note = row.note;
    // this.isFreeAccount = row.isFreeAccount || false;
    this.dialogUpdateUser = true
  }

  async handleUpdateAccount() {
    let updateForm: any = {}
    updateForm.id = this.account
    updateForm.name = this.nameAccount
    updateForm.isFreeAccount = this.isFreeAccount
    updateForm.note = this.note
    let res: Result
    await this.$store.dispatch('setLoading', true, { root: true })
    res = await axios.put('admin/account', updateForm, {
      headers: this.headers
    })
    if (res.status) {
      this.$message.success(res.message)
      await this.getAllAccount(this.currentPage)
      await this.$store.dispatch('setLoading', false, { root: true })
      this.dialogUpdateAccount = false
    } else {
      this.$message.warning(res.message)
      await this.getAllAccount(this.currentPage)
      await this.$store.dispatch('setLoading', false, { root: true })
      this.dialogUpdateAccount = false
    }
  }

  async handleUpdate() {
    let res: Result
    let updateForm: any = {}
    updateForm.id = this.id
    updateForm.skip_2fa = this.skip_2fa
    updateForm.fisrtLogin = this.fisrtLogin
    updateForm.accountId = this.account
    updateForm.type = this.userType

    if (this.userType === UserType.Partner) {
      updateForm.platformPartner = this.platformPartnerId
    }

    if (this.isEmailChanged) {
      updateForm.email = this.email
    }
    if (this.isStatusChanged) {
      updateForm.status = this.status
    }
    if (this.isAccountOwneChanged) {
      updateForm.isAccountOwner = this.isAccountOwner
    }
    if (this.isAccountOwner) {
      updateForm.newBraintreeId = this.newBraintreeId
    }
    if (this.isPasswordChanged) {
      if (!(typeof this.password === 'string' && this.password !== ''))
        return this.$message.warning('Please enter a password!', 'Error')
      if (
        !(
          typeof this.confirmPassword === 'string' &&
          this.confirmPassword !== ''
        )
      )
        return this.$message.warning(
          'Please enter confirmation password!',
          'Error'
        )
      if (this.password !== this.confirmPassword)
        return this.$message.warning('Please match 2 password fields!', 'Error')
      updateForm.password = this.password
    }

    // if (this.isAccountChanged) {
    //   if (!(typeof this.account === "string" && this.account !== ""))
    //     return this.$message.warning("Please choose a Account!", "Error");
    //   if (!(typeof this.nameAccount === "string" && this.nameAccount !== ""))
    //     return this.$message.warning("Please enter a name Account!", "Error");
    //   updateForm.account = this.account;
    //   updateForm.nameAccount = this.nameAccount;
    // }
    console.log(updateForm)

    res = await axios.put('admin/user', updateForm, {
      headers: this.headers
    })
    if (res) {
      this.$message.success(res.message)
      this.dialogUpdateUser = false
      this.isEmailChanged = false
      this.isStatusChanged = false
      this.isPasswordChanged = false
      this.isAccountChanged = false
      this.isAccountOwneChanged = false
      this.platformPartnerId = ''
      this.userType = -1
      this.isNoteChanged = false
      this.isAccountOwner = false
      this.newBraintreeId = ''
      await this.getAllAccount(this.currentPage)
    }
  }
  async handleSearch() {
    this.isShowSearch = !this.isShowSearch
    this.condition.name = undefined
  }
  resultQuery() {
    this.getAllAccount(this.currentPage)
  }
  clearQuery() {
    this.condition.name = undefined
    this.getAllAccount(this.currentPage)
  }
  closeDialog() {
    this.isEmailChanged = false
    this.isStatusChanged = false
    this.isPasswordChanged = false
    this.isAccountChanged = false
    this.isAccountOwneChanged = false
    this.platformPartnerId = ''
    this.userType = -1
    this.isAccountOwner = false
    this.newBraintreeId = ''
  }
}
